@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DeckPopup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(black, 0.5);
	padding: 5em 20em;
}

.DeckPopup-content {
	position: relative;
	width: 100%;
	min-height: 30em;
	background: white;
	border-radius: 0.33em;
	padding: 2em;
	overflow: auto;
}

.DeckPopup-backBtn {
	position: absolute;
	top: 1em;
	right: 1em;
	width: 1.5em;
	height: 1.5em;
	background-image: url('../../../assets/images/icon-close.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.DeckPopup-saveDeckBtn {
	display: inline-block;
	background-color: red;
	opacity: 0.3;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-bottom: 2em;
	cursor: not-allowed;
	&.active {
		opacity: 1;
		color: white;
		background-color: green;
		cursor: pointer;
		&:hover {background-color: darken(green, 5%);}

	}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.DeckPopup-section {
	font-size: 1.125em;
	margin-bottom: 2em;
}

.DeckPopup-sectionTitle {
	font-size: 1.125em;
	font-weight: bold;
	text-transform: uppercase;
}

.DeckPopup-deckInfo {
	margin: 0.35em 0;
	input {
		width: 10em;
		line-height: 1;
		padding: 0.25em;
		height: 1.5em;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
  	-webkit-appearance: none;
  	margin: 0;
	}
	input[type=number] {
  	-moz-appearance: textfield;
	}
	span {
		display: inline-block;
		vertical-align: top;
		font-weight: bold;
		width: 5em;
	}
}

.DeckPopup-decks {
	display: inline-block;
}

.DeckPopup-decksHeader > div {
	font-weight: bold;
	cursor: default;
}

.DeckPopup-deckId,
.DeckPopup-deckTitle,
.DeckPopup-deckCards,
.DeckPopup-deckDelete
 {
	display: inline-block;
	vertical-align: top;
	width: 5em;
}
.DeckPopup-deckTitle {
	width: 10em;
}

.DeckPopup-deckDelete {
	width: 2em;
	cursor: pointer;
	img {
		width: 0.75em;
	}
}

.DeckPopup-deck {
	&:hover {background-color: darken(white, 5%);}
}