@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	@include defaultPage();
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 100vh;
	background-image: 
		url('../../assets/images/background-login-top.svg'), 
		url('../../assets/images/background-login-bottom.svg'),
		url('../../assets/images/background-splash-middle.svg');
	background-size: 100% auto;
	background-position: top center, bottom center, center center;
	background-repeat: no-repeat, no-repeat, repeat-y;
	@include translate(0, -100%);
	&--active {@include translate(0, 0%);}
	&--slideOut {@include animateSlideOut();}
	&--slideIn {@include animateSlideIn();}
}

.Login-form {
	position: relative;
	margin: auto;
	width: calc(100% - 5.5em);
	input {
		width: 100%;
		height: 3.22em;
		line-height: 3.22;
		text-align: left;
		padding: 0 0.5em;
		margin-bottom: 0.6em;
		border: 1px solid #BEBEBE;
	}
	&.create {
		padding-top: 2.5em;
	}
}

.Login-formTitle {
	margin-bottom: 0.5em;
	text-transform: uppercase;
	font-weight: bold;

}
.Login-errorMessage {
	width: 100%;
	text-align: left;
	height: 2em;
	color: red;
	span {
		font-size: 0.85em;
	}
}

.Login-loginBtn {
	position: relative;
	margin: auto;
	width: 4.25em;
	height: 2.65em;
	background-image: url('../../assets/images/button-login.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	text-align: center;
	padding-top: 0.15em;
	span {
		color: black;
		font-weight: bold;
		font-style: italic;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	&:hover {
		background-image: url('../../assets/images/button-login-hover.svg');
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			left: calc(50% - 0.75em);
			top: 3em;
			width: 1.5em;
			height: 1.5em;
			@include origin(50%, 50%);
			-webkit-animation: loadingSpin 0.5s ease-in-out 0s infinite;
			animation: loadingSpin 0.5s ease-in-out 0s infinite;
			background-image: url('../../assets/images/icon-loading-key.svg');
			background-size: auto 1.5em;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}	
}

// .Login-conditions {
// 	margin-bottom: 2em;
// 	padding: 0 0.5em;
// 	.Login-conditionsCheckbox {
// 		display: inline-block;
// 		vertical-align: middle;
// 		width: 1.5em;
// 		height: 1.5em;
// 		background-color: white;
// 		border: 1px solid #BEBEBE;
// 		margin-right: 0.5em;
// 		&.checked {
// 			background-image: url('../../assets/images/icon-check.svg');
// 			background-size: contain;
// 			background-position: center center;
// 			background-repeat: no-repeat;
// 		}
// 	}
// 	.Login-conditionsText {
// 		display: inline-block;
// 		vertical-align: middle;
// 		white-space: nowrap;
// 		.Login-conditionsLink {
// 			display: inline-block;
// 			text-decoration: underline;
// 		}
// 	}
// }

.Login-createUserBtn {
	text-align: center;
	cursor: pointer;
	text-decoration: underline;
	margin-bottom: 3em;
}

@keyframes loadingSpin {
	from {@include rotate(0deg);}
	to {@include rotate(360deg);}
}
@-webkit-keyframes loadingSpin {
	from {@include rotate(0deg);}
	to {@include rotate(360deg);}
}