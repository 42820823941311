@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Transfer {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
}

.Transfer-backBtn {
	position: absolute;
	top: 1em;
	left: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Admin-button {
	display: inline-block;
	width: 7em;
	background-color: white;
	padding: 0.5em 0.8em;
	margin: 0.5em 0;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Transfer-title {
	font-size: 2em;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 1em;
}

.Transfer-section {
	margin-bottom: 4em;
}

.Transfer-btn,
.Transfer-link,
.Transfer-uploadFile {
	display: inline-block;
	width: 7em;
	background-color: white;
	padding: 0.5em 0.8em;
	margin: 0.5em 0;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
	&.loading {
		opacity: 0.5;
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 90%;
		background-position: center right 0.5em;
		background-repeat: no-repeat;
	}
}

.Transfer-link {
	display: block;
	width: 24em;
}

