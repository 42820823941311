@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
	.SurveyPreview {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(black, 0.5);
		@include flex('center', 'center');
	}

	.SurveyPreview-content {
		position: relative;
		width: 20em;
		padding: 0em;
		background-color: white;
	}

	.SurveyPreview-closeBtn {
		position: absolute;
		top: 0.75em;
		right: 0.75em;
		width: 1.25em;
		height: 1.25em;
		background-image: url('../../../assets/images/icon-close.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		z-index: 3;
		cursor: pointer;
	}


	.SurveyPreview-survey {
		position: relative;
		margin: 0em auto;
		width: 20em;
		height: 32em;
	}