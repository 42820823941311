@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Info {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 6em 1.75em 1.75em 1.75em;
	background-color:#FFF6F7;
	background-image: url('../../assets/images/background-info.svg');
	background-size: 100% auto;
	background-position: top 2em center;
	background-repeat: no-repeat;

	.Info-text {
		text-align: left;
		margin-bottom: 2em;
		p {
			margin-top: 0;
			font-size: 0.9em;
		}
	}

	.Info-logo {
		width: 100%;
		height: 2.25em;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		margin: 0.75em 0;
		&.tryg {background-image: url('../../assets/images/logo-tryg.png');}
		&.region {background-image: url('../../assets/images/logo-region-s.png');}
		&.cgl {background-image: url('../../assets/images/logo-cgl.png');}
	}

	.Info-closeBtn {
		position: absolute;
		bottom: 2em;
		left: calc(50% - 2em);
		width: 4em;
		font-size: 0.85em;
		font-weight: bold;
		padding: 0.25em 0;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid black;
		margin: 2em auto 1em auto;
		background-color: white;
		@include no-select();
	}
}