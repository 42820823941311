@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SurveyPopup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(black, 0.5);
	padding: 5em 20em;
	hr {
		margin-bottom: 2em;
	}

}

.SurveyPopup-content {
	position: relative;
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 0.33em;
	padding: 2em;
	overflow: auto;
}

.SurveyPopup-backBtn {
	position: absolute;
	top: 1em;
	right: 1em;
	width: 1.5em;
	height: 1.5em;
	background-image: url('../../../assets/images/icon-close.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.SurveyPopup-surveyId {
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: 0.5em;
}

.SurveyPopup-saveSurveyBtn {
	display: inline-block;
	background-color: red;
	opacity: 0.3;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-bottom: 2em;
	cursor: not-allowed;
	&--active {
		opacity: 1;
		color: white;
		background-color: green;
		cursor: pointer;
		&:hover {background-color: darken(green, 5%);}

	}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}
.SurveysPopup-newStepBtn {
	display: inline-block;
	background-color: red;
	opacity: 0.3;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin: 2em 0;
	cursor: not-allowed;
	&--active {
		opacity: 1;
		color: white;
		background-color: green;
		cursor: pointer;
		&:hover {background-color: darken(green, 5%);}

	}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.SurveyPopup-previewBtn {
	display: inline-block;
	background-color: green;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-bottom: 2em;
	margin-left: 1em;
	color: white;
	cursor: pointer;
}

.SurveyPopup-section {
	font-size: 1.125em;
	margin-bottom: 2em;
	input {
		width: 10em;
		line-height: 1;
		padding: 0.25em;
		height: 1.5em;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
  	-webkit-appearance: none;
  	margin: 0;
	}
	input[type=number] {
  	-moz-appearance: textfield;
	}
	textarea,
	textarea:focus {
		font-size: 0.85em;
		width: 17.65em; // 15em adjusted
		height: 8.24em; // 7em adjusted
		padding: 0.3em; // 0.25em adjusted
	}
	.Select {margin-bottom: 0.1em;}
	&--answer {
		.Select {width: 15em;}
		.SurveyPopup-subsection:nth-of-type(3) {
			width: 22em;
			.SurveyPopup-surveyInfo span {width: 6em;}
		}
	}
	&--survey {
		.SurveyPopup-subsection:nth-of-type(2) {
			width: 22em;
			.SurveyPopup-surveyInfo span {width: 6em;}
		}
	}

}

.SurveyPopup-subSection {
	border: 1px solid rgba(black, 0.2);
	padding: 0.5em;
	width: 21em;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2em;
	&:nth-child(odd) {margin-right: 3em}
}
.SurveyPopup-sectionTitle {
	font-size: 1.125em;
	font-weight: bold;
	text-transform: uppercase;
}

.SurveyPopup-surveyInfo {
	margin: 0.35em 0;
	span {
		display: inline-block;
		vertical-align: top;
		font-weight: bold;
		width: 5em;
	}
}

.SurveyPopup-deckTitle {
	span {
		display: inline-block;
		width: 5em;
	}
	margin-bottom: 1em;

}

 .SurveyPopup-section input.SurveyPopup-statInput {
	width: 3em;
	margin-right: 1em;
	background-size: auto 1em;
	background-position: center right 0.1em;
	background-repeat: no-repeat;
	text-align: left;
		&--shield {background-image: url('../../../assets/images/stats/shield-filled.svg');}
		&--heart {background-image: url('../../../assets/images/stats/heart-filled.svg');}
		&--sun {background-image: url('../../../assets/images/stats/sun-filled.svg');}
		&--drop {background-image: url('../../../assets/images/stats/water-filled.svg');}
}