@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Missions {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
	.Missions-backBtn {
		position: absolute;
		top: 1em;
		left: 2em;
		background-color: white;
		padding: 0.5em 0.8em;
		border-radius: 0.3em;
		font-weight: bold;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
	}
	
	.Missions-card {
		height: 1.5em;
		overflow: hidden;
	}
	
	.Missions-newMissionBtn {
		display: inline-block;
		background-color: white;
		padding: 0.5em 0.8em;
		border-radius: 0.3em;
		font-weight: bold;
		margin-top: 2em;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
		&--saving {
			background-image: url('../../../assets/images/icon-loading.svg');
			background-size: auto 80%;
			background-position: right center;
			background-repeat: no-repeat;
		}
	}
	
	.Missions-title {
		font-size: 2em;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 1em;
	}
	
	.Missions-section {
		margin-bottom: 4em;
	}
	
	
	.Missions-header {
		>div {
			font-weight: bold;
			background-image: none;
		}
	}
	
	.Missions-row {
		margin: 0.25em 0;
		cursor: pointer;
		&:hover {background-color: rgba(white, 0.2);}
	}
	.Missions-missionId,
	.Missions-missionTitle,
	.Missions-missionCards {
		display: inline-block;
		vertical-align: top;
		width: 5em;
	}
	.Missions-missionTitle {
		width: 20em;
	}
	.Missions-missionCards {
		width: auto;
	}
}

