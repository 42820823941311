@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Progress {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5em;
	transition: top 1s;
	background-image: linear-gradient(#52B8FF, #FFF6F6);
	text-align: center;
	overflow: hidden;
	-webkit-animation: darkenSky 0s linear 40s forwards;
	animation: darkenSky 0s linear 40s forwards;
	&--hidden {
		top: -5em;
		background-color: transparent;
		-webkit-animation: none;
		animation: none;
	}
}

.Progress-satellite {
	position: absolute;
	@include origin(100%, 50%);
	@include rotate(35deg);
	right: 50%;
	bottom: -2.5em;
	width: 6.65em;
	height: 1.2em;
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
	&--sun {
		background-image: url('../../assets/images/progress/icon-sun.svg');
		-webkit-animation: moveSun 40s linear 0s forwards;
		animation: moveSun 40s linear 0s forwards;
	}
	&--moon {
		opacity: 0;
		background-image: url('../../assets/images/progress/icon-moon2.svg');
		-webkit-animation: moveMoon 20s linear 40s forwards;
		animation: moveMoon 20s linear 40s forwards;
	}
}

.Progress-maze {
	position: absolute;
	width: 100%;
	height: 100%;
	width: 100%;
	background-image: url('../../assets/images/progress/maze-floor.svg');
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
}

.Progress-keyHoles {
	position: absolute;
	top: 1.9em;
	left: 0;
	width: 100%;
	text-align: center;
	&--3 {
		left: 45%;
		width: 10%;
		.Progress-keyHole.Progress-keyHole--1 {left: 0; top: 0.25em;}
		.Progress-keyHole.Progress-keyHole--2 {left: calc(50% - 0.15em); top: 0em;}
		.Progress-keyHole.Progress-keyHole--3 {right: 0; top: 0.25em;}
	}
	&--5 {
		left: 41%;
		width: 18%;
		.Progress-keyHole.Progress-keyHole--1 {left: 0; top: 1em;}
		.Progress-keyHole.Progress-keyHole--2 {left: calc(25% - 0.15em); top: 0.25em;}
		.Progress-keyHole.Progress-keyHole--3 {left: calc(50% - 0.15em); top: 0em;}
		.Progress-keyHole.Progress-keyHole--4 {left: calc(75% - 0.15em); top: 0.25em;}
		.Progress-keyHole.Progress-keyHole--5 {right: 0; top: 1em;}
	}
}

.Progress-keyHole {
	position: absolute;
	width: 0.3em;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}

@-webkit-keyframes darkenSky {
	0% {background-image: linear-gradient(#52B8FF, #FFF6F6);}
	100% {background-image: linear-gradient(#9A95FE, #2B20FD);}
}
@keyframes darkenSky {
	0% {background-image: linear-gradient(#52B8FF, #FFF6F6);}
	100% {background-image: linear-gradient(#9A95FE, #2B20FD);}
}

@-webkit-keyframes moveSun {
	0% {opacity: 1; @include rotate(35deg);}
	99% {opacity: 1; @include rotate(145deg);}
	100% {opacity: 0; @include rotate(145deg);}
}
@keyframes moveSun {
	0% {opacity: 1; @include rotate(35deg);}
	99% {opacity: 1; @include rotate(145deg);}
	100% {opacity: 0; @include rotate(145deg);}
}

@-webkit-keyframes moveMoon {
	0% {opacity: 1; @include rotate(35deg);}
	100% {opacity: 1; @include rotate(90deg);}
}
@keyframes moveMoon {
	0% {opacity: 1; @include rotate(35deg);}
	100% {opacity: 1; @include rotate(90deg);}
}