@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
html {
  font-size: calc(18 * ((100vw / 360)));
}

body {
  font-family: 'Roboto Condensed', monospace;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: black;
  background-color: white;
  touch-action: manipulation; // disables double-tap gestur -> no click delay by the browser
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
}

* {box-sizing: border-box;}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  font-size: inherit;
}

input[type='submit'] {
	@include appearance(none);
}

// @media (min-width: 320px) {
//   html {
//     font-size: calc(18 * ((100vw / 360)));
//   }
// }

@media (min-aspect-ratio: "9/16") {
  html {
    font-size: calc(18 * ((100vh / 640)));
  }
}
