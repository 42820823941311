@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.PortalMini {
	position: absolute;
	height: 3em;
	width: 4em;
	background-image: url('../../assets/images/progress/maze-portal.svg');
	background-size: 2em auto;
	background-position: bottom 0.3em center;
	background-repeat: no-repeat;
	&--progress {
		left: calc(50% - 2em);
		bottom: 0;
	}
	&--overview {
		@include scale(0.9);
		top: 0.3em;
		right: 3.6em;
	}
}


.PortalMini-keyHoles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	&--3 {
		.PortalMini-keyHole.PortalMini-keyHole--1 {@include translate(-1em, 0.35em);}
		.PortalMini-keyHole.PortalMini-keyHole--3 {@include translate(1em, 0.35em);}
	}
	&--4 {
		.PortalMini-keyHole.PortalMini-keyHole--1 {@include translate(-1em, 0.35em);}
		.PortalMini-keyHole.PortalMini-keyHole--2 {@include translate(-.35em, 0em);}
		.PortalMini-keyHole.PortalMini-keyHole--3 {@include translate(0.35em, 0em);}
		.PortalMini-keyHole.PortalMini-keyHole--4 {@include translate(1em, 0.35em);}
	}
	&--5 {
		.PortalMini-keyHole.PortalMini-keyHole--1 {@include translate(-1.45em, 1em);}
		.PortalMini-keyHole.PortalMini-keyHole--2 {@include translate(-0.9em, 0.3em);}
		.PortalMini-keyHole.PortalMini-keyHole--4 {@include translate(0.9em, 0.3em);}
		.PortalMini-keyHole.PortalMini-keyHole--5 {@include translate(1.45em, 1em);}
	}
}

.PortalMini-keyHole {
	position: absolute;
	top: 0;
	left: calc(50% - 0.15em);
	width: 0.3em;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}