@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Cards {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
}

.Cards-backBtn {
	position: absolute;
	top: 1em;
	left: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Cards-card {
	height: 1.5em;
	overflow: hidden;
}

.Cards-newCardBtn,
.Cards-newDeckBtn {
	display: inline-block;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-top: 2em;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.Cards-newDeckBtn {
	margin: 0 0 2em 0;
}

.Cards-title {
	font-size: 2em;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 1em;
}

.Cards-section {
	margin-bottom: 4em;
	&:not(.basic) {
		.Cards-cardId {
			width: 8em;
		}
		.Cards-cardMazeId {
			width: 0;
		}
		
	}
}

.Cards-deckTitle {
	span {
		display: inline-block;
		width: 5em;
	}
	margin-bottom: 1em;
}

.Cards-areas {
	margin-bottom: 2em;
	.Cards-area {
		display: inline-block;
		vertical-align: top;
		margin-right: 2em;
	}
}
.Cards-types {
	margin-bottom: 2em;
	.Cards-type {
		display: inline-block;
		vertical-align: top;
		margin-right: 2em;
	}
}

.Cards-cards {
	font-size: 1.25em;
}
.Cards-header {
	>div {
		font-weight: bold;
		background-image: none;
	}
}
.Cards-card {
	margin-bottom: 0.25em;
	&:hover {background-color: rgba(white, 0.2);}
	cursor: pointer;
}



.Cards-cardPreview,
.Cards-cardId,
.Cards-cardMazeId,
.Cards-cardTitle,
.Cards-cardType,
.Cards-cardColor,
.Cards-cardImage,
.Cards-cardText,
.Cards-cardOption,
.Cards-optionText,
.Cards-optionEffects,
.Cards-cardStats,
.Cards-cardStat,
.Cards-cardFeedback,
.Cards-cardMission,
.Cards-cardSecret,
.Cards-cardNext {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	white-space: nowrap;
}

.Cards-cardPreview {
	width: 2em;
	height: 1.5em;
	background-image: url('../../../assets/images/icon-eye.svg');
	background-size: 1em auto;
	background-position: center center;
	background-repeat: no-repeat;
}

.Cards-cardId {
	width: 5em;
}
.Cards-cardMazeId {
	width: 3em;
}
.Cards-cardTitle {
	width: 7em;
}
.Cards-cardType {
	width: 3em;
}
.Cards-cardColor {
	width: 5em;
}
.Cards-cardImage {
	width: 10em;
	
}

.Cards-cardText {
	p {margin: 0; display: inline-block;}
	width: 20em;
	margin-right: 1em;
}

.Cards-cardOption {
	width: 20em;
	margin-right: 1em;
}

.Cards-optionText {
	width: 7em;
}
.Cards-optionEffects {
	width: 4em;
	margin: 0 1em;
}

.Cards-cardStat {
	padding-right: 1em;
	background-size: auto 1em;
	background-position: center right;
	background-repeat: no-repeat;
	text-align: left;
	&--shield {background-image: url('../../../assets/images/stats/shield.svg');}
	&--heart {background-image: url('../../../assets/images/stats/heart.svg');}
	&--sun {background-image: url('../../../assets/images/stats/sun.svg');}
	&--drop {background-image: url('../../../assets/images/stats/water.svg');}
}