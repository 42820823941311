@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Intro {
	@include defaultPage();
	justify-content: center;
	background-color: #FFF6F6;
	background-image: url('../../assets/images/background-intro.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	color: $text-dark;
	overflow: hidden;
}

.Intro-cardContainer {
	position: relative;
	margin: 0 auto;
	width: calc(100vw - 3em);
	height: calc(100vw - 2.5em);
	will-change: transform;
	background-image: url('../../assets/images/decks/pink-back.svg'), url('../../assets/images/decks/pink-deck.svg'), ;
	background-size: calc(96.2%) auto, 100% auto;
	background-position: top left, top left;
	background-repeat: no-repeat, no-repeat;
	padding: 0 3% calc(3.7% + 0.3em) 0;
}

.Intro-cardContent {
	width: 100%;
	height: 100%;
	padding: 6em 2em 2em 2em;
	border: 0.5px solid black;
	background: white;
	text-align: center;
	transform-origin: bottom center;
	p {margin: 0;}	
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	@include no-select();
}
.Intro-cardBtn {
	position: absolute;
	bottom: 3em;
	left: calc(50% - 1.5em);
	width: 3em;
	padding: 0.25em 0;
	text-transform: uppercase;
	text-align: center;
	border: 1px solid black;
	margin: auto;
	background-color: white;
	@include no-select();
}