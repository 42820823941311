@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
@mixin slider-thumb($color) {
	@include appearance(none);
	height: 3.5em;
	width: 0.75em;
	margin-top: -0.75em;
	background: #{$color};
	cursor: pointer;
}

@mixin slider-track() {
	@include appearance(none);
	width: 100%;
	height: 2em;
	background-image: linear-gradient(to right, #61FEB3, #FEF55F, #FC4949); 
	cursor: pointer;
}




.RangeSlider {
	position: relative;
	width: 100%;
	height: 3em;
	input[type=range] {
		width: 100%;
		height: 100%;
		font-size: 1em;
		background: transparent;
		@include appearance(none);
	}
	input[type=range]:focus {outline: none;}

	input[type=range]::-webkit-slider-runnable-track {@include slider-track();}				// Chrome, Safari, Opera
	input[type=range]:focus::-webkit-slider-runnable-track {@include slider-track();}	// Chrome, Safari, Opera
	input[type=range]::-moz-range-track {@include slider-track();}										// Firefox
	input[type=range]::-ms-track {@include slider-track();}														// IE, Edge
	input[type=range]::-ms-tooltip {display: none;}																		// IE, Edge

	input[type=range]::-webkit-slider-thumb {@include slider-thumb(#61FEB3);}			// Chrome, Safari, Opera
	input[type=range]::-moz-range-thumb {@include slider-thumb(#61FEB3);}					// Firefox
	input[type=range]::-ms-thumb {@include slider-thumb(#61FEB3);}									// IE, Edge

	$colors: 10;
	@for $i from 0 through $colors {
		$color: #61FEB3;
		@if ($i >= 2) { $color: #B2F988; }
		@if ($i >= 4) { $color: #FEF55F; }
		@if ($i >= 6) { $color: #FDA755; }
		@if ($i >= 8) { $color: #FC4949; }
		&--#{$i} {
			input[type=range]::-webkit-slider-thumb {@include slider-thumb($color);}			// Chrome, Safari, Opera
			input[type=range]::-moz-range-thumb {@include slider-thumb($color);}					// Firefox
			input[type=range]::-ms-thumb {@include slider-thumb($color);}									// IE, Edge
		}
	}
}

// How to style range input:
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

