@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameStats {
	font-size: 1vw;
	height: 100vh;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 1em 2em;
}

.GameStats-title {
	font-size: 2em;
	margin-bottom: 1em;
}


.GameStats-logout {
	position: absolute;
	top: 1em;
	right: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	&:hover {background-color: darken(white, 5%);}
}

.GameStats-users {
	display: inline-block;
	border: 1px solid black;
	font-size: 1.25em;

}

.GameStats-headers {
	border-bottom: 1px solid black;
}
.GameStats-header {
	display: inline-block;
	vertical-align: top;
	width: 15em;
	font-weight: bold;
	text-transform: uppercase;
	padding: 0.5em;	
	cursor: pointer;
	@include no-select();
	span {
		display: inline-block;
		width: 1em;
		height: 0.75em;
		background-image: url('../../assets/images/icon-sortable.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.GameStats-user {
	cursor: pointer;
	&:hover {
		background-color: rgba(white, 0.2);
	}
}

.GameStats-data {
	display: inline-block;
	vertical-align: top;
	width: 15em;
	padding: 0.25em 0.5em;
}