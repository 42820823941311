@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Portal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #52B8FF;
	opacity: 0;
	@include animateFadeIn();

	.Portal-ground {
		position: absolute;
		width: 100%;
		top: 60vh;
		left : 0;
		bottom: 0;
		background-image: url('../../assets/images/portal/background-ground.svg');
		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.Portal-keyHoles {
		position: absolute;
		top: 2em;
		left: 0;
		height: 5em;
		text-align: center;
		&--3 {
			left: calc(50% - 6em);
			width: 12em;
			.Portal-keyHole.Portal-keyHole--1 {left: 0; top: 1em;}
			.Portal-keyHole.Portal-keyHole--2 {left: calc(50% - 0.75em); top: 0em;}
			.Portal-keyHole.Portal-keyHole--3 {right: 0; top: 1em;}
		}
		&--4 {
			left: calc(50% - 5em);
			width: 10em;
			.Portal-keyHole.Portal-keyHole--1 {left: 0; top: 1.5em;}
			.Portal-keyHole.Portal-keyHole--2 {left: calc(50% - 2.1em); top: 0em;}
			.Portal-keyHole.Portal-keyHole--3 {right: calc(50% - 2.1em); top: 0em;}
			.Portal-keyHole.Portal-keyHole--4 {right: 0; top: 1.5em;}
		}
		&--5 {
			left: calc(50% - 7.5em);
			width: 15em;
			.Portal-keyHole.Portal-keyHole--1 {left: 0; top: 2em;}
			.Portal-keyHole.Portal-keyHole--2 {left: calc(25% - 0.75em); top: 0.5em;}
			.Portal-keyHole.Portal-keyHole--3 {left: calc(50% - 0.75em); top: 0em;}
			.Portal-keyHole.Portal-keyHole--4 {left: calc(75% - 0.75em); top: 0.5em;}
			.Portal-keyHole.Portal-keyHole--5 {right: 0; top: 2em;}
		}
		.Portal-keyHole {
			position: absolute;
			width: 1.25em;
			height: 2.45em;
			border-radius: 2.45em;
			svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
				&--animate {
				svg .cls-1 {
					-webkit-animation: animateKeyHoleColor 3s ease-in-out 0s forwards;
					animation: animateKeyHoleColor 3s ease-in-out 0s forwards;
				}
				.Portal-keyHoleGlow {
					-webkit-animation: animateKeyHoleGlow 3s ease-in-out 0s forwards;
					animation: animateKeyHoleGlow 3s ease-in-out 0s forwards;
				}
			}
	
			.Portal-keyHoleGlow {
				position: absolute;
				left: 0.375em;
				top: 0.25em;
				width: 0.5em;
				height: 2em;
				border-radius: 1em;
				background-color: white;
				@include box-shadow(0, 0, 0.9em, 0.3em, white);
				@include opacity(0);
			}
		}
	}

	.Portal-portal {
		position: absolute;
		left: calc(50% - 9.5em);
		top: calc(67.5vh - 15em);
		width: 19em;
		height: 15em;
		background-image: url('../../assets/images/portal/background-portal-1.svg');
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
		.Portal-gate {
			width: 100%;
			height: 100%;
			background-image: url('../../assets/images/portal/gate.svg');
			background-size: 59.8% auto;
			background-position: top 31.5% left 51%;
			background-repeat: no-repeat;
			&.animate {
				-webkit-animation: animatePortalGate 2s ease-in-out 0s forwards;
				animation: animatePortalGate 2s ease-in-out 0s forwards;
			}
			&.open {@include opacity(0);}
		}
		&--2 {
			background-image: url('../../assets/images/portal/background-portal-2.svg');
			.Portal-gate {
				background-size: 61.5% auto;
				background-position: top 26% left 51%;
			}
		}
		&--3 {
			top: calc(67.5vh - 17em);
			height: 17em;	
			background-image: url('../../assets/images/portal/background-portal-3.svg');
			.Portal-gate {
				background-position: top 48.5% left 50%;
			}
		}
		&--4 {
			top: calc(67.5vh - 17em);
			height: 17em;	
			background-image: url('../../assets/images/portal/background-portal-4.svg');
			.Portal-gate {
				background-position: top 49.5% left 50%;
			}
		}

		&--5 {
			top: calc(67.5vh - 22em);
			height: 22em;	
			background-image: url('../../assets/images/portal/background-portal-5.svg');
			.Portal-gate {
				background-position: top 70.5% left 51%;
			}
		}
	}

	.Portal-maze {
		position: absolute;
		bottom: 0;
		background-size: contain;
		background-repeat: no-repeat;
		&.left {
			left: 0;
			width: 8.75em;
			height: 6.5em;
			background-image: url('../../assets/images/portal/maze-left.svg');
			background-position: bottom left;
			&.animate {
				-webkit-animation: animateLeftMaze 2s ease-in-out 0s forwards;
				animation: animateLeftMaze 2s ease-in-out 0s forwards;
			}
		}
		&.right {
			right: 0;
			bottom: 4em;
			width: 4.25em;
			height: 4.5em;
			background-position: bottom right;
			background-image: url('../../assets/images/portal/maze-right.svg');
			&.animate {
				-webkit-animation: animateRightMaze 2s ease-in-out 0s forwards;
				animation: animateRightMaze 2s ease-in-out 0s forwards;
			}
		}
		&.open {
			display: none;
		}
	}	

	.Portal-nextBtn {
		position: absolute;
		left: calc(50% - 4.6875em);
		bottom: 6.5em;
		width: 9.375em;
		height: 3.75em;
		background-image: url('../../assets/images/button-menu-hover.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		text-align: center;
		padding-top: 0.15em;
		span {
			color: black;
			font-weight: bold;
			font-style: italic;
			font-size: 1.5em;
			text-transform: uppercase;
		}
	}
}

@-webkit-keyframes animateKeyHoleColor {
	0% {fill: #000;}
	20% {fill: #FEF55F;}
	100% {fill: #FEF55F;}
}
@keyframes animateKeyHoleColor {
	0% {fill: #000;}
	20% {fill: #FEF55F;}
	100% {fill: #FEF55F;}
}

@-webkit-keyframes animateKeyHoleGlow {
	0% {@include opacity(0);}
	20% {@include opacity(0.5);}
	35% {@include opacity(0);}
	50% {@include opacity(0.5);}
	65% {@include opacity(0);}
	80% {@include opacity(0.5);}
	100% {@include opacity(0);}
}
@keyframes animateKeyHoleGlow {
	0% {@include opacity(0);}
	20% {@include opacity(0.5);}
	35% {@include opacity(0);}
	50% {@include opacity(0.5);}
	65% {@include opacity(0);}
	80% {@include opacity(0.5);}
	100% {@include opacity(0);}
}

@-webkit-keyframes animatePortalGate {
	0% {@include opacity(1);}
	10% {@include opacity(0.5);}
	20% {@include opacity(1);}
	30% {@include opacity(0.5);}
	40% {@include opacity(1);}
	50% {@include opacity(0.5);}
	60% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes animatePortalGate {
	0% {@include opacity(1);}
	10% {@include opacity(0.5);}
	20% {@include opacity(1);}
	30% {@include opacity(0.5);}
	40% {@include opacity(1);}
	50% {@include opacity(0.5);}
	60% {@include opacity(1);}
	100% {@include opacity(0);}
}

@-webkit-keyframes animateLeftMaze {
	0% {@include translate(0, 0);}
	100% {@include translate(-100%, 0);}
}
@keyframes animateLeftMaze {
	0% {@include translate(0, 0);}
	100% {@include translate(-100%, 0);}
}

@-webkit-keyframes animateRightMaze {
	0% {@include translate(0, 0);}
	100% {@include translate(100%, 0);}
}
@keyframes animateRightMaze {
	0% {@include translate(0, 0);}
	100% {@include translate(100%, 0);}
}