@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.MazeGame {
	@include defaultPage();
	height: auto;
	overflow: hidden;
	padding-top: 5em;
	background-color: #FFF6F6;
	background-size: cover;
	color: $text-dark;
	&.night {
		background-color: white;
		background-image: url('../../assets/images/background-night.svg'), linear-gradient(#4D4DFF 70%, #4D4DFF, #7878FF, #7878FF);
		background-size: cover, auto;
		.MazeGame-cardContainer {
			background-image: url('../../assets/images/decks/blue-back.svg'), url('../../assets/images/decks/blue-deck.svg'), ;
		}
	}
}

.MazeGame-text {
	width: 100%;
	height: 7.44em;
	padding: 1em 1.5em 0.5em 1.5em;
	line-height: 1.25;
	text-align: left;
	@include no-select();
	span {
		display: inline-block;
		vertical-align: middle;
		width: 1em;
		height: 1.5em;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		&.shield {background-image: url('../../assets/images/stats/shield.svg');}
		&.heart {background-image: url('../../assets/images/stats/heart.svg');}
		&.sun {background-image: url('../../assets/images/stats/sun.svg');}
		&.drop {background-image: url('../../assets/images/stats/water.svg');}
	}
	p {
		margin-top: 0;
	}
}

.MazeGame-cardContainer {
	position: relative;
	margin: 0em auto;
	width: calc(100vw - 3em);
	height: calc(100vw - 2.5em);
	will-change: transform;
	background-image: url('../../assets/images/decks/pink-back.svg'), url('../../assets/images/decks/pink-deck.svg'), ;
	background-size: calc(96.2%) auto, 100% auto;
	background-position: top left, top left;
	background-repeat: no-repeat, no-repeat;
	padding: 0 3% calc(3.7% + 0.3em) 0;
}

.MazeGame-overviewBtn {
	width: 4em;
	height: 4em;
	margin: auto;
	background-image: url('../../assets/images/icon-labyrinth.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}