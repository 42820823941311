@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Overview {
	@include defaultPage();
	position: absolute;
	top: 0;
	overflow: hidden;
	.Overview-mazes {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100vw * 5 * (640.0 / 360.0));
		@include translate(0, calc(-100vw * 4 * (640.0 / 360.0)));
		-webkit-transition-duration: 1s;
		transition-duration: 1s;
		-webkit-transition-delay: 0.25s;
		transition-delay: 0.25s;
		
		-webkit-transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
		transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);

		display: flex; 
		flex-direction: column-reverse;
		.Overview-maze {
			position: relative;
			padding: 0;
			img {
				display: block;
				width: 100%;
			}
		}
		&--2 {
			@include translate(0, calc(-100vw * 3 * (640.0 / 360.0)));
		}
		&--3 {
			@include translate(0, calc(-100vw * 2 * (640.0 / 360.0)));
		}
		&--4 {
			@include translate(0, calc(-100vw * 1 * (640.0 / 360.0)));
		}
		&--5 {

	
			@include translate(0, 0);
		}
	}

	.Overview-keyAnimation2 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../assets/images/animation-key3.gif');
		// background-size: 6.688em 10.56em;
		background-size: 0 0;
		background-position: center center;
		background-repeat: no-repeat;
		@include rotate(0deg);
		&.show {
			-webkit-animation: keyAnimation 2.5s ease-in-out 0s 1 forwards;
			animation: keyAnimation 2.5s ease-in-out 0s 1 forwards;	
		}
	}
}


// .Overview-keyAnimation {
// 	position: absolute;
// 	top: 0;
// 	left: calc(50% - 8em);
// 	width: 16em;
// 	opacity: 1;
// 	img {
// 		display: none;
// 		width: 100%;
// 	}
// 	&.show {
// 		transition: opacity 0s linear 2.28s;
// 		opacity: 0;
// 		img {
// 			display: block;
// 		}
// 	}
// }

@-webkit-keyframes keyAnimation {
	0% {background-size: 0 0; background-position: center center; @include rotate(-14deg);}
	1.5% {background-size: 0.24em auto; @include rotate(-14deg);}
	3% {background-size: 1.08em auto; @include rotate(-14deg);}
	4.5% {background-size: 2.50em auto; @include rotate(-14deg);}
	6% {background-size: 4.5em auto; @include rotate(-6deg);}
	7.5% {background-size: 6.8em auto;@include rotate(-3deg);}
	9% {background-size: 8.71em auto; @include rotate(0deg);}
	10.5% {background-size: 10.04em auto;}
	12% {background-size: 10.74em auto;}
	13.5% {background-size: 10.98em auto;}
	15% {background-size: 10.98em auto;}
	16.5% {background-size: 10.74em auto;}
	18% {background-size: 10.56em auto; background-position: left 50% top 50%;} 		// #13
	60% {background-size: 10.56em auto; background-position: left 50% top 50%;} 		// #41
	61.5% {background-size: 10.56em auto; background-position: left 50% top 50%; @include rotate(0deg);} 	// #42
	63% {background-size: 10.46em auto; background-position: left 50% top 50%; @include rotate(2deg);}
	64.5% {background-size: 10.18em auto; background-position: left calc(50% + (0.17 * (50% - 5.4em))) top calc(50% - (0.12 * (50% - 0.3em))); @include rotate(2deg);}
	66% {background-size: 9.94em auto; background-position: left calc(50% + (0.28 * (50% - 5.4em))) top calc(50% - (0.24 * (50% - 0.3em))); @include rotate(2deg);} 		//#45
	67.5% {background-size: 9.94em auto; background-position: left calc(50% + (0.41 * (50% - 5.4em))) top calc(50% - (0.37 * (50% - 0.3em))); @include rotate(2deg);}
	69% {background-size: 10.23em auto; background-position: left calc(50% + (0.56 * (50% - 5.4em))) top calc(50% - (0.50 * (50% - 0.3em))); @include rotate(2deg);} 		// #47
	70.5% {background-size: 10.79em auto; background-position: left calc(50% + (0.72 * (50% - 5.4em))) top calc(50% - (0.65 * (50% - 0.3em))); @include rotate(2deg);}	// #48
	72% {background-size: 10.56em auto; background-position: left calc(50% + (0.87 * (50% - 5.4em))) top calc(50% - (0.77 * (50% - 0.3em))); @include rotate(2deg);}
	73.5% {background-size: 9.19em auto; background-position: left calc(50% + (0.93 * (50% - 5.4em))) top calc(50% - (0.84 * (50% - 0.3em))); @include rotate(2deg);}
	75% {background-size: 7.40em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (0.89 * (50% - 0.3em))); @include rotate(2deg);}
	76.5% {background-size: 5.47em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (0.95 * (50% - 0.3em))); @include rotate(2deg);}
	78% {background-size: 3.53em auto; background-position: left calc(50% + (1.05 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); @include rotate(2deg);}
	79.5% {background-size: 1.65em auto; background-position: left calc(50% + (1.05 * (50% - 5.4em))) top calc(50% - (1.05 * (50% - 0.3em))); @include rotate(2deg);}
	81% {background-size: 0.57em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); @include rotate(2deg);}
	82.5% {background-size: 0 0; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); ; @include rotate(2deg);}						// #56
	100% {background-size: 0 0; background-position: top 0.3em right 5.4em; @include rotate(2deg);}
};
@keyframes keyAnimation {
	0% {background-size: 0 0; background-position: center center; @include rotate(-14deg);}
	1.5% {background-size: 0.24em auto; @include rotate(-14deg);}
	3% {background-size: 1.08em auto; @include rotate(-14deg);}
	4.5% {background-size: 2.50em auto; @include rotate(-14deg);}
	6% {background-size: 4.5em auto; @include rotate(-6deg);}
	7.5% {background-size: 6.8em auto;@include rotate(-3deg);}
	9% {background-size: 8.71em auto; @include rotate(0deg);}
	10.5% {background-size: 10.04em auto;}
	12% {background-size: 10.74em auto;}
	13.5% {background-size: 10.98em auto;}
	15% {background-size: 10.98em auto;}
	16.5% {background-size: 10.74em auto;}
	18% {background-size: 10.56em auto; background-position: left 50% top 50%;} 		// #13
	60% {background-size: 10.56em auto; background-position: left 50% top 50%;} 		// #41
	61.5% {background-size: 10.56em auto; background-position: left 50% top 50%; @include rotate(0deg);} 	// #42
	63% {background-size: 10.46em auto; background-position: left 50% top 50%; @include rotate(2deg);}
	64.5% {background-size: 10.18em auto; background-position: left calc(50% + (0.17 * (50% - 5.4em))) top calc(50% - (0.12 * (50% - 0.3em))); @include rotate(2deg);}
	66% {background-size: 9.94em auto; background-position: left calc(50% + (0.28 * (50% - 5.4em))) top calc(50% - (0.24 * (50% - 0.3em))); @include rotate(2deg);} 		//#45
	67.5% {background-size: 9.94em auto; background-position: left calc(50% + (0.41 * (50% - 5.4em))) top calc(50% - (0.37 * (50% - 0.3em))); @include rotate(2deg);}
	69% {background-size: 10.23em auto; background-position: left calc(50% + (0.56 * (50% - 5.4em))) top calc(50% - (0.50 * (50% - 0.3em))); @include rotate(2deg);} 		// #47
	70.5% {background-size: 10.79em auto; background-position: left calc(50% + (0.72 * (50% - 5.4em))) top calc(50% - (0.65 * (50% - 0.3em))); @include rotate(2deg);}	// #48
	72% {background-size: 10.56em auto; background-position: left calc(50% + (0.87 * (50% - 5.4em))) top calc(50% - (0.77 * (50% - 0.3em))); @include rotate(2deg);}
	73.5% {background-size: 9.19em auto; background-position: left calc(50% + (0.93 * (50% - 5.4em))) top calc(50% - (0.84 * (50% - 0.3em))); @include rotate(2deg);}
	75% {background-size: 7.40em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (0.89 * (50% - 0.3em))); @include rotate(2deg);}
	76.5% {background-size: 5.47em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (0.95 * (50% - 0.3em))); @include rotate(2deg);}
	78% {background-size: 3.53em auto; background-position: left calc(50% + (1.05 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); @include rotate(2deg);}
	79.5% {background-size: 1.65em auto; background-position: left calc(50% + (1.05 * (50% - 5.4em))) top calc(50% - (1.05 * (50% - 0.3em))); @include rotate(2deg);}
	81% {background-size: 0.57em auto; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); @include rotate(2deg);}
	82.5% {background-size: 0 0; background-position: left calc(50% + (1 * (50% - 5.4em))) top calc(50% - (1 * (50% - 0.3em))); ; @include rotate(2deg);}						// #56
	100% {background-size: 0 0; background-position: top 0.3em right 5.4em; @include rotate(2deg);}
};