@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Missions2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1em 2.5em;
	background-color:#FFF6F7;

	.Missions-title {
		font-size: 1.5em;
		text-transform: uppercase;
		font-weight: bold;
		font-style: italic;
		text-align: center;
		margin-bottom: 1.5em;
	}

	.Missions-missions {
		position: absolute;
		top: 4.5em;
		bottom: 5em;
		left: 2.5em;
		right: 2.5em;
		overflow: auto;
	
		.Missions-section {
			margin-bottom: 1.5em;
			.Missions-sectionTitle {
				text-transform: uppercase;
				font-weight: bold;
				font-style: italic;
				font-size: 0.85em;
			}
			.Missions-mission {
				padding-left: 2em;
				line-height: 1.5;
				margin: 0.2em;
				background-image: url('../../assets/images/mazes/key-rotated.svg');
				background-size: auto 1.3em;
				background-position: left center;
				background-repeat: no-repeat;
			}
			&.completed .Missions-mission {
				background-image: url('../../assets/images/mazes/key-rotated-unlocked.svg');
			}
		}
	}

	.Missions-closeBtn {
		position: absolute;
		bottom: 2em;
		left: calc(50% - 2em);
		width: 4em;
		font-size: 0.85em;
		font-weight: bold;
		padding: 0.25em 0;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid black;
		margin: 2em auto 1em auto;
		background-color: white;
		@include no-select();
	}
}