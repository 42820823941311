@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Select {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 10em;
	background-color: white;
	background-image: url('../../assets/images/icon-arrow-down.svg');
	background-size: auto 65%;
	background-position: center right 0.25em;
	background-repeat: no-repeat;
	&.open {
		.Select-dropdown {
			height: auto;
			max-height: 15em;
			overflow: auto;
			border: 0.053em solid black;
		}
		.Select-button {
			top: 0.8em;
			// background-image: url('../../assets/images/icon-arrow-up.svg');
		}
	}
	
	.Select-panel {
		border: 1px solid black;
		height: 1.5em;
		padding: 0.25em;
		cursor: pointer;
		// &--null .Select-selected{
		// 	color: $placeholder-grey;
		// }
	}
	
	.Select-selected {
		text-align: left;
		line-height: 1;
	}
	.Select-button {
		position: absolute;
		top: 0.9em;
		right: 0.4em;
		width: 0.8em;
		height: 0.8em;
		// background-image: url('../../assets/images/icon-arrow-down.svg');
		background-position: center right;
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: 1.5em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;
			color: black;
			text-align: left;
			border-top: 0.1em solid grey;
			padding: 0.25em;
			line-height: 1;
			height: 1.5em;
			white-space: nowrap;
		}
	}
}