@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.TheEnd {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #5ABBFD;
	background-image: url('../../assets/images/background-the-end.svg');
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
	padding: 2em 3em;
	text-align: center;
	&.card {
		@include flex('center', 'center');
	}

	.TheEnd-text {
		color: white;
		text-align: center;
	}

	.TheEnd-btn {
		display: inline-block;
		margin-top: 1.5em;
		width: 5em;
		padding: 0.25em 0;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid black;
		margin: 2em auto 1em auto;
		background-color: white;
		@include no-select();
	}

	.TheEnd-cardContainer {
		position: absolute;
		margin: 0em auto;
		width: calc(100vw - 3em);
		height: calc(100vw - 2.5em);
		left: calc(1.5em + 1.5%);
		background-color: white;
		background-image: url('../../assets/images/cards/feedback.svg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		padding: 1.5em 2em;
		@include flex('center', 'center');
		.TheEnd-card {
			width: 100%;
			text-align: center;			
			.TheEnd-cardBtn {
				width: 3em;
				padding: 0.25em 0;
				text-transform: uppercase;
				text-align: center;
				border: 1px solid black;
				margin: 2em auto 0 auto;
				background-color: white;
				@include no-select();
			}
		}
	}
}