@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ConnectionStatus {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	background-color: white;
	padding: 0.45em;
	opacity: 0;
	z-index: 100;
	@include transition('opacity', 0.3s, 'ease-in-out');
	&--offline {opacity: 1;}
}