@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Images {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
}

.Images-backBtn {
	position: absolute;
	top: 1em;
	left: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Images-title {
	font-size: 2em;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 1em;
}

.Images-section {
	margin-bottom: 4em;
}

.Images-uploadBtn {
	width: 8em;
	background-color: white;
	padding: 0.25em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-top: 0.25em;
	text-align: center;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Images-errorMsg {
	height: 1em;
	margin-top: 1em;
	color: rgb(221, 41, 95);
}

.Images-image {
	position: relative;
	padding: 0.5em;
	display: inline-block;
	img {width: 7em;}
	&:hover {
		.Images-imageDeleteBtn {
			display: block;
		}
	}
}

.Images-imageDeleteBtn {
	display: none;
	position: absolute;
	top: 0.75em;
	right: 0.75em;
	width: 1em;
	height: 1em;
	border-radius: 1em;
	border: 1px solid black;
	background-color: white;
	background-image: url('../../../assets/images/icon-close.svg');
	background-size: 50% auto;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		@include scale(1.1);
	}
}

.Images-errorPopup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(black, 0.5);
	@include flex('center', 'center');
}

.Image-errorPopupContent {
	background: white;
	padding: 2em;
	border-radius: 0.25em;
}