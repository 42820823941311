@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Surveys {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
}

.Surveys-backBtn {
	position: absolute;
	top: 1em;
	left: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}


.Surveys-newSurveyBtn {
	display: inline-block;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-top: 2em;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.Surveys-title {
	font-size: 2em;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 1em;
}

.Surveys-section {
	margin-bottom: 4em;
}

.Surveys-deckTitle {
	span {
		display: inline-block;
		width: 5em;
	}
	margin-bottom: 1em;
}

.Surveys-surveys {
	font-size: 1.25em;
}
.Surveys-header {
	>div {
		font-weight: bold;
		background-image: none;
	}
}
.Surveys-row {
	height: 1.5em;
	overflow: hidden;
	margin-bottom: 0.25em;
	&:hover {background-color: rgba(white, 0.2);}
	cursor: pointer;
}

.Surveys-surveyPreview,
.Surveys-surveyId,
.Surveys-surveyMazeId,
.Surveys-surveyTitle,
.Surveys-surveyType,
.Surveys-surveyCards,
.Surveys-surveySteps {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	white-space: nowrap;
}

.Surveys-surveyPreview {
	width: 2em;
	height: 1.5em;
	background-image: url('../../../assets/images/icon-eye.svg');
	background-size: 1em auto;
	background-position: center center;
	background-repeat: no-repeat;
}

.Surveys-surveyId {
	width: 5em;
}
.Surveys-surveyMazeId {
	width: 3em;
}
.Surveys-surveyTitle {
	width: 7em;
}
.Surveys-surveyType {
	width: 5em;
}
.Surveys-surveyCards {
	width: 30em;
}