@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.PortalFeedback {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #FFF6F6;
	background-image: url('../../assets/images/background-intro.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	@include flex('center', 'center');
	.PortalFeedback-cardContainer {
		position: relative;
		margin: 0em auto;
		width: calc(100vw - 3em);
		height: calc(100vw - 2.5em);
		background-image: url('../../assets/images/decks/pink-back.svg'), url('../../assets/images/decks/pink-deck.svg'), ;
		background-size: calc(96.2%) auto, 100% auto;
		background-position: top left, top left;
		background-repeat: no-repeat, no-repeat;
		padding: 0 3% calc(3.7% + 0.3em) 0;
	}
}