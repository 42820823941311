@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.UserStats {
	font-size: 1vw;
	// height: 100vh;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em;
}

.UserStats-title {
	font-size: 2em;
	margin-bottom: 1em;
}


.UserStats-backBtn {
	position: absolute;
	top: 1em;
	left: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

hr {
	margin: 0.125em 0;
}

.UserStats-section {
	font-size: 1.25em;
	margin-bottom: 3em;
}

.UserStats-sectionTitle {
	font-weight: bold;
	text-transform: uppercase;
}

.UserStats-info {
	display: inline-block;
	vertical-align: top;
	padding: 0em 3em 0em 0em;	
	
	span {
		font-weight: bold;
		text-transform: uppercase;
	}
}


.UserStats-mazes {
	font-size: 1.25em;
}
.UserStats-maze {
	margin-bottom: 1em;
}
.UserStats-mazeTitle {
	font-weight: bold;
}
.UserStats-surveyTitle {
	display: inline-block;
	vertical-align: top;
	width: 10em;
	font-size: 0.85em;
}
.UserStats-surveySteps {
	display: inline-block;
	vertical-align: top;
	width: 60em;
}
.UserStats-surveyStep {
	width: 100%;
	margin-bottom: 0.5em;
}
.UserStats-surveyQ,
.UserStats-surveyA {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	font-size: 0.85em;
	padding: 0 1em;
}