@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.SurveysPrint {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1em 2.5em;
	background-color:#FFF6F7;

	.SurveysPrint-title {
		font-size: 1.5em;
		text-transform: uppercase;
		font-weight: bold;
		font-style: italic;
		text-align: center;
		margin-bottom: 0.5em;
	}

	.SurveysPrint-therapist {
		width: 100%;
		height: 8.5em;
		background-image: url('../../assets/images/surveys/therapist-2.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.SurveysPrint-text,
	.SurveysPrint-text2 {
		p {
			font-size: 0.85em;
		}
	}
	.SurveysPrint-text2 {
		text-align: center;
	}

	.SurveysPrint-form {
		position: relative;
		margin: auto;
		width: calc(100% - 2em);
		input {
			width: 100%;
			height: 3.22em;
			line-height: 3.22;
			text-align: left;
			padding: 0 0.5em;
			margin-bottom: 0.6em;
			border: 1px solid #BEBEBE;
		}
	}

	.SurveysPrint-errorMessage,
	.SurveysPrint-successMessage {
		font-size: 0.85em;
		color: red;
		text-align: center;
	}
	.SurveysPrint-successMessage {
		color: black;
	}


	.SurveysPrint-sendBtn,
	.SurveysPrint-closeBtn {
		position: relative;
		width: 5em;
		font-size: 0.85em;
		font-weight: bold;
		padding: 0.25em 0;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid black;
		margin: auto;
		background-color: white;
		@include no-select();
		&.loading {
			&::after {
				content: "";
				position: absolute;
				left: calc(50% - 0.75em);
				top: 2em;
				width: 1.5em;
				height: 1.5em;
				@include origin(50%, 50%);
				-webkit-animation: loadingSpin 0.5s ease-in-out 0s infinite;
				animation: loadingSpin 0.5s ease-in-out 0s infinite;
				background-image: url('../../assets/images/icon-loading-key.svg');
				background-size: auto 1.5em;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}

	.SurveysPrint-closeBtn {
		position: absolute;
		bottom: 2em;
		left: calc(50% - 2.5em);
		margin: 2em auto 1em auto;
	}
}
