@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.AdminLogin {
	font-size: 1vw;
	height: 100vh;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 1em 2em;
}

.AdminLogin-title {
	font-size: 2em;
}

.AdminLogin-form {
	margin-top: 2em;
	input {
		margin-top: 0.25em;
		display: inline-block;
		font-family: 'Roboto Condensed';
		font-size: 1em;
		padding: 0.25em;
		width: 15em;
		border: 1px solid black;
	}
	button {
		display: inline-block;
		width: 7em;
		text-transform: uppercase;
		margin-top: 1em;
		font-family: 'Roboto Condensed';
		background-color: white;
		border: 1px solid black;
		&.Button--loading {
			background-image: url('../../assets/images/icon-loading.svg');
			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position: right center;
		}
	}
}