@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
  width: 100vw;
  min-height: 100vh;
	color: $text-dark;
	background-color: white;
	overflow-x: hidden;
}